import React,{useEffect} from 'react';

const PageNotFound = () => {

    useEffect(()=>{
        window.location.href = 'https://gatewayadmin.superfan.studio/';
    },[])
    return (
        <>
            <div style={{ fontSize: "30px", textAlign: "center", paddingTop: "10%" }}>
                 Redirecting...
            </div>
            {/* <p class="mb-4" style={{textAlign:"center"}}>Oops! 😖  Redirecting...</p> */}
        </>
    )
}

export default PageNotFound;