import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ReactComponent as FeatherHome } from '../app-assets/images/icons/Icon feather-home.svg';
import { ReactComponent as SettingIcon } from "../app-assets/images/icons/Icon ionic-ios-settings.svg";
import { ReactComponent as BriefCaseImage } from "../app-assets/images/icons/briefcase.svg";
import { ReactComponent as ConnectionImage } from '../app-assets/images/icons/connection.svg';
import { ReactComponent as DeviceImage } from "../app-assets/images/icons/devices.svg";
import { ReactComponent as DiscountImage } from '../app-assets/images/icons/discount.svg';
import { ReactComponent as MegaPhoneImage } from "../app-assets/images/icons/megaphone.svg";
import { ReactComponent as SupportImage } from "../app-assets/images/icons/support.svg";
import { ReactComponent as UploadImage } from "../app-assets/images/icons/upload.svg";
import { ROUTES } from '../constants/routes';
import './sidebar.css';
const LeftSideNav = (props) => {
    const location = useLocation();

    const goToDashboard = () => {
        props.history.push(ROUTES.HOME)
    }
    const goToOffer = () => {
        props.history.push(ROUTES.OFFER);
    }
    const goToDevice = () => {
        props.history.push(ROUTES.DEVICE)
    }
    const goToMarketing = () => {
        props.history.push(ROUTES.MARKETING)
    }
    const goToResources = () => {
        props.history.push(ROUTES.RESOURCES)
    }
    const goToSupport = () => {
        props.history.push(ROUTES.SUPPORT)
    }
    const goToSetting = () => {
        props.history.push(ROUTES.SETTING)
    }
    const goToDataDevice = () => {
        props.history.push(ROUTES.INSIGHT)
    }
    const goToFileSharing = () => {
        props.history.push(ROUTES.FILE_SHARING)
    }
    return (
        <>
            <div className="navbar-header">
                <ul className="nav navbar-nav flex-row">
                    <li className="nav-item mr-auto">
                        &nbsp;
                    </li>
                    <li className="nav-item nav-toggle">
                        &nbsp;
                    </li>
                </ul>
            </div>
            <div className="shadow-bottom"></div>
            <div className="main-menu-content" >
                <ul className="navigation navigation-main pt-1" id="main-menu-navigation" data-menu="menu-navigation" >

                    <li style={{ paddingTop: "6px" }} className={"nav-item " + (location.pathname === ROUTES.DASHBOARD ? " active" : "")}>
                        <a className="d-flex align-items-center" onClick={() => goToDashboard()}>
                            <FeatherHome className="sidebar-menu-mage" style={{
                                color: location.pathname === ROUTES.DASHBOARD ? 'black' : 'white'
                            }} />
                            <span className="menu-title text-truncate fs-12" data-i18n="Dashboard" style={{ color: "#000" }}><FormattedMessage id="Home" /></span>
                        </a>
                    </li>
                    <li style={{ paddingTop: "6px" }} className={"nav-item " + (location.pathname === ROUTES.OFFER ? " active" : "")}>
                        <a className="d-flex align-items-center" onClick={goToOffer} >
                            <DiscountImage className="sidebar-menu-mage" style={{
                                color: location.pathname === ROUTES.OFFER ? 'black' : 'white'
                            }} />
                            <span className="menu-title text-truncate fs-12" data-i18n="Offers" style={{ color: "#000" }}><FormattedMessage id="Campaigns" /></span>
                        </a>
                    </li>


                    <li style={{ paddingTop: "6px" }} className={"nav-item " + (location.pathname === ROUTES.RESOURCES ? " active" : "")}>
                        <a className="d-flex align-items-center" onClick={goToResources}>
                            <BriefCaseImage className="sidebar-menu-mage" style={{
                                color: location.pathname === ROUTES.RESOURCES ? 'black' : 'white'
                            }} />
                            <span className="menu-title text-truncate fs-12" data- i18n="Resources" style={{ color: "#000" }}><FormattedMessage id="Zero Rating" /></span>
                        </a>
                    </li>

                    <li style={{ paddingTop: "6px" }} className={"nav-item " + (location.pathname === ROUTES.MARKETING ? " active" : "")}>
                        <a className="d-flex align-items-center" onClick={goToMarketing}>
                            <MegaPhoneImage className="sidebar-menu-mage" style={{
                                color: location.pathname === ROUTES.MARKETING ? 'black' : 'white'
                            }} />
                            <span className="menu-title text-truncate fs-12" data-i18n="Marketing" style={{ color: "#000" }}><FormattedMessage id="Marketing" /></span>
                        </a>
                    </li>

                    <li style={{ paddingTop: "6px" }} className={"nav-item " + (location.pathname === ROUTES.INSIGHT ? " active" : "")}>
                        <a className="d-flex align-items-center" onClick={goToDataDevice}>
                            <ConnectionImage className="sidebar-menu-mage" style={{
                                color: location.pathname === ROUTES.INSIGHT ? 'black' : 'white'
                            }} />
                            <span className="menu-title text-truncate fs-12" data-i18n="Data & Insights" style={{ color: "#000" }}><FormattedMessage id="Data & Insights" /></span>
                        </a>
                    </li>

                    <li style={{ paddingTop: "6px" }} className={"nav-item " + (location.pathname === ROUTES.FILE_SHARING ? " active" : "")}>
                        <a className="d-flex align-items-center" onClick={goToFileSharing}>
                            <UploadImage className="sidebar-menu-mage" style={{
                                color: location.pathname === ROUTES.FILE_SHARING ? 'black' : 'white'
                            }} />
                            <span className="menu-title text-truncate fs-12" data-i18n="File Sharing" style={{ color: "#000" }}><FormattedMessage id="File Sharing" /></span>
                        </a>
                    </li>
                    <li style={{ paddingTop: "6px" }} className={"nav-item " + (location.pathname === ROUTES.DEVICE ? " active" : "")}>
                        <a className="d-flex align-items-center" onClick={goToDevice}>
                            <DeviceImage className="sidebar-menu-mage" style={{
                                color: location.pathname === ROUTES.DEVICE ? 'black' : 'white'
                            }} />
                            <span className="menu-title text-truncate fs-12" data-i18n="Devices" style={{ color: "#000" }}><FormattedMessage id="Devices" /></span>
                        </a>
                    </li>
                    <li style={{ paddingTop: "6px" }} className={"nav-item " + (location.pathname === ROUTES.SUPPORT ? " active" : "")}>
                        <a className="d-flex align-items-center" onClick={goToSupport}>
                            <SupportImage className="sidebar-menu-mage" style={{
                                color: location.pathname === ROUTES.SUPPORT ? 'black' : 'white'
                            }} />
                            <span className="menu-title text-truncate fs-12" data-i18n="Support" style={{ color: "#000" }}><FormattedMessage id="Support" /></span>
                        </a>
                    </li>
                    <li style={{ paddingTop: "6px" }} className={"nav-item " + (location.pathname === ROUTES.SETTING ? " active" : "")}>
                        <a className="d-flex align-items-center" onClick={goToSetting}>
                            <SettingIcon className="sidebar-menu-mage" style={{
                                color: location.pathname === ROUTES.SETTING ? 'black' : 'white'
                            }} />
                            <span className="menu-title text-truncate fs-12" data-i18n="Feather" style={{ color: "#000" }}><FormattedMessage id="Settings" /></span>
                        </a>
                    </li>
                </ul>
                <div style={{ position: "absolute", bottom: "10px", width: "100%", padding: "10px 15px 10px 15px" }}>
                    <div style={{ margin: "0px 15px" }}>
                        <a style={{ fontSize: "13px", color: "#F8F8F8", fontWeight: "500", letterSpacing: '0.4px', marginLeft: '2px' }} href="https://www.snap.com/en-US/terms/mobile-partner-gateway-terms" target="_blank" rel="noreferrer"> <FormattedMessage id="Terms & Conditions" /> </a>
                    </div>
                </div>
            </div >

        </>
    )
}
export default LeftSideNav;