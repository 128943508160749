import { Bubble } from 'react-chartjs-2';
import { Card, CardBody, CardTitle } from 'reactstrap';

const BubbleChart = ({ options, data }) => {
  return (
    <Card
      style={{
        borderRadius: '16px',
        boxShadow: '4px 4px 0px 0px #CCCCCC'
      }}
    >
      <CardTitle
        className="p-2 card-header-bg"
        style={{
          borderBottom: "1px solid #CCCCCC",
          color: 'black',
          fontSize: '18px',
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px"
        }}
      >
        Latency vs Error Rates
      </CardTitle>
      <CardBody>
        <Bubble options={options} data={data} />
      </CardBody>
    </Card>
  )
}

export default BubbleChart
