export const ROUTES = {
  HOME: '/',
  DASHBOARD: '/home',
  LOGIN: '/login',
  REGISTER: '/register',
  OFFER: '/offer',
  DEVICE: '/device',
  MARKETING: '/marketing',
  RESOURCES: '/resources',
  SETTING: '/setting',
  SUPPORT: '/support',
  INSIGHT: '/insight',
  FILE_SHARING: '/file-sharing',
  ADMIN: '/admin',
}