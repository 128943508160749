export function trimText(text, maxLength) {
  if (text.length <= maxLength) return text; // No need to trim

  if (text.substring('<img')) {
    return text
  }

  // Find the index of the last whitespace before or at maxLength
  let lastSpaceIndex = text.lastIndexOf(' ', maxLength);

  // If no whitespace is found, return the original text
  if (lastSpaceIndex === -1) return text;

  // Trim the text up to the last whitespace and append ellipsis
  return text.substring(0, lastSpaceIndex) + '...';
}