import axios from 'axios'
import 'filepond/dist/filepond.min.css'
import { useEffect, useState } from 'react'
import { FileText, Upload, X } from 'react-feather'
import { FilePond } from 'react-filepond'
import { FormattedMessage } from 'react-intl'
import { TagsInput } from "react-tag-input-component"
import { toast } from 'react-toastify'
import {
  Button,
  Form, FormFeedback,
  FormGroup, Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Spinner
} from 'reactstrap'
import './tagStyle.css'

const UploadModalForm = (props) => {

  const [scrollInnerModal, setScrollInnerModal] = useState(false)
  const [tags, setTags] = useState([]);
  const [currentFile, setCurrentFile] = useState();
  const [fileError, setFileError] = useState(false)
  const [offerList, setOfferList] = useState([])
  const [buttonLoading, setButtonLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  const [fieldErrors, setFieldErrors] = useState({
    title: false,
    offerType: false,
    description: false,
  })

  const [title, setTitle] = useState('');
  const [offerType, setOfferType] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    resetForm()
  }, [scrollInnerModal])

  const resetForm = () => {
    setTitle('');
    setOfferType('');
    setDescription('');
    setTags([]);
    setCurrentFile(null);
    setFileError(false);
    setUploadProgress(0);
  };

  useEffect(() => {
    getOfferList()
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Request canceled by cleanup");
      }
    };
  }, [])

  const getOfferList = () => {
    // Fetch the presigned URL from your backend
    const cancelToken = axios.CancelToken.source();
    setCancelTokenSource(cancelToken);
    axios({
      url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + `/getOffersByCompany`,
      method: 'GET',
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept": 'application/json',
      }
    }).then((response) => {
      setOfferList(response.data.data)
    }).catch((error) => {
      toast.error("No offers found")
    })
  }


  const onSubmit = async () => {
    if (title && description && currentFile) {
      setButtonLoading(true);

      if (currentFile === null) {
        setFileError(true)
      }

      const data = offerType.length > 0 ? {
        title: title,
        offerType: offerType,
        description: description,
        tags: tags,
        file: currentFile
      } : {
        title: title,
        description: description,
        tags: tags,
        file: currentFile
      }

      props.setLoading(true)


      const fileName = new Date().toISOString() + "_" + currentFile.name;

      try {
        const presignedUrlResponse = await axios({
          url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + `/assets/upload?key=${fileName}&fileType=${currentFile.type}`,
          method: 'GET',
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Accept": 'application/json',
          }
        });

        if (presignedUrlResponse.status < 200 || presignedUrlResponse.status >= 300) {
          throw new Error('Failed to get presigned URL');
        }

        const { url } = presignedUrlResponse.data;

        const uploadResponse = await axios.put(url, currentFile, {
          headers: {
            "Content-Type": currentFile.type,
          },
          onUploadProgress: (progressEvent) => {
            setUploadProgress(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          },
          cancelToken: cancelTokenSource.token,
        });

        if (uploadResponse.status >= 200 && uploadResponse.status < 300) {
          // Store the URL in MongoDB
          const offerDocumentResponse = await axios({
            url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + `/createOfferDocument`,
            method: 'POST',
            withCredentials: true,
            data: {
              uploaded_by: props.userEmail,
              offer_id: data.offerType,
              file_name: currentFile.name,
              file_title: data.title,
              file_description: data.description,
              file_url: url,
              offer_tags: tags,
              file_key: fileName,
              file_type: currentFile.type,
              file_size: currentFile.size,
            },
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Accept": 'application/json',
            }
          });

          if (offerDocumentResponse.status >= 200 && offerDocumentResponse.status < 300) {
            toast.success('Offer file uploaded successfully')
          } else {
            toast.error('Error uploading file')
          }

        } else {
          throw new Error('Failed to upload file to S3');
        }
        props.getAllFiles(1)
        setUploadProgress(0);

      } catch (error) {
        toast.error('Upload unsuccessful');
      } finally {
        setScrollInnerModal(false)
        props.setLoading(false)
        setButtonLoading(false)
      }
    }
    else {
      const errors = {
        title: false,
        offerType: false,
        description: false,
      }
      if (!description) {
        errors.description = true
      }
      if (!title) {
        errors.title = true
      }
      setFieldErrors(errors);
    }
  }

  function formatFileSize(sizeInBytes) {
    let size;
    const KB = 1024;
    const MB = KB * 1024;
    const GB = MB * 1024;

    if (sizeInBytes < KB) {
      size = `${sizeInBytes} bytes`;
    } else if (sizeInBytes < MB) {
      size = `${(sizeInBytes / KB).toFixed(2)} KB`;
    } else if (sizeInBytes < GB) {
      size = `${(sizeInBytes / MB).toFixed(2)} MB`;
    } else {
      size = `${(sizeInBytes / GB).toFixed(2)} GB`;
    }
    return size;
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Upload canceled by user');
      setButtonLoading(false);
      setScrollInnerModal(!scrollInnerModal);
      setUploadProgress(0)
    }
  };

  return (
    <div className='demo-inline-spacing'>

      <Button
        style={{
          color: "white",
          margin: "0px",
          background: 'black'
        }}
        onClick={() => {
          setScrollInnerModal(!scrollInnerModal)
        }}
      >
        <Upload style={{ width: '18px', marginRight: '12px' }} />
        <FormattedMessage id="Upload Document" />
      </Button>

      <Modal scrollable isOpen={scrollInnerModal} toggle={() => setScrollInnerModal(!scrollInnerModal)} style={{ marginTop: '56px' }} >
        <ModalHeader toggle={() => setScrollInnerModal(!scrollInnerModal)}>Upload File</ModalHeader>

        <ModalBody>
          {(uploadProgress > 0) ? (
            <div className='d-flex w-100 align-items-center border p-1 rounded'>
              <FileText size={50} />
              <div className="w-100 progress-wrapper pl-2">
                <div className="d-flex justify-content-between ">
                  <div className="d-flex flex-column pb-2">
                    <div className='text-capitalize' style={{ fontSize: "16px", fontWeight: 500 }}>
                      {currentFile?.name || ""}
                    </div>
                    <div style={{ fontSize: "10px", color: "#595959" }}>
                      {formatFileSize(currentFile?.size || 1)}
                    </div>
                  </div>
                  <X className="border cursor-pointer rounded" onClick={cancelUpload} />
                </div>

                <Progress
                  className="progress-bar-rounded"
                  color="dark"
                  value={uploadProgress}
                >
                  {uploadProgress}%
                </Progress>
              </div>
            </div>
          )
            :
            <Form >
              <FormGroup >
                <div className={fileError && "invalid-file"}>
                  <FilePond
                    allowMultiple={false}
                    maxFiles={1}
                    server={{
                      process: (fieldName, file, metadata, load, error, progress, abort) => {
                        load();
                      }
                    }}
                    onupdatefiles={(fileItems) => {
                      if (fileItems.length > 0 && fileItems[0].file) {
                        setCurrentFile(fileItems[0].file);
                        setFileError(false)
                      }
                    }}
                  />
                </div>
                {fileError ? <Label className="text-danger">File is required</Label> : null}
              </FormGroup>

              <FormGroup>
                <Label for='title'>Title</Label>
                <Input
                  type="text"
                  id='title'
                  name='title'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  invalid={fieldErrors.title}
                />
                {fieldErrors.title ? <FormFeedback>Title is required</FormFeedback> : ""}
              </FormGroup>

              <FormGroup>
                <Label>
                  Offers
                </Label>
                <Input
                  type="select"
                  id="offerType"
                  name='offerType'
                  value={offerType}
                  onChange={(e) => setOfferType(e.target.value)}
                  invalid={fieldErrors.offerType}
                >
                  <option value="">Select offer</option>

                  {offerList.length > 0 ? offerList.map((data, index) => {
                    return (
                      <option value={data._id} key={index}>
                        {data.offer_name}
                      </option>
                    )
                  }) : null}

                </Input>
                {fieldErrors.offerType ? <FormFeedback>Offer required</FormFeedback> : ""}
              </FormGroup>

              <FormGroup>
                <Label for='description'>Description</Label>
                <Input
                  type="textarea"
                  id='description'
                  name='description'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  invalid={fieldErrors.description}
                // placeholder='Wayne'
                />
                {fieldErrors.description ? <FormFeedback>Description required</FormFeedback> : ""}
              </FormGroup>

              <FormGroup>
                <Label for='title'>Tags</Label>
                <TagsInput
                  id="tags"
                  name="tags"
                  placeHolder="Type to add tags"
                  onChange={setTags}
                />
              </FormGroup>
              <FormGroup className='d-flex mb-0 justify-content-end ' style={{ marginTop: "20px" }}>
                <Button
                  disabled={buttonLoading}
                  className={`mr-1 ${buttonLoading ? 'px-3' : ''}`}
                  style={{
                    color: "white",
                    background: 'black'
                  }}
                  onClick={() => {
                    currentFile ? setFileError(false) : setFileError(true)
                    onSubmit();
                  }}>
                  {buttonLoading ? <Spinner /> : "Upload"}
                </Button>
                <Button outline color='secondary' type='reset' onClick={cancelUpload}>
                  Cancel
                </Button>
              </FormGroup>
            </Form>
          }


        </ModalBody>


      </Modal>
    </div>
  )
}
export default UploadModalForm;
