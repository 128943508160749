import { Pie } from 'react-chartjs-2';
import { Card, CardBody, CardTitle } from 'reactstrap';

const PieChart = ({ options, data, className }) => {

  return (
    <Card
      style={{
        borderRadius: '16px',
        boxShadow: '0px 4px 0px 0px #CCCCCC',
        border: '1px solid #CCCCCC',
      }}
      className={`${className}`}
    >
      <CardTitle
        className="p-2 card-header-bg"
        style={{
          borderBottom: "1px solid #CCCCCC",
          color: 'black',
          fontSize: '18px',
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px"
        }}
      >
        Gender Ratio
      </CardTitle>
      <CardBody
        style={{
          padding: '0',
          display: 'flex',
          flexDirection: "column",
          gap: '12px',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ width: '130%', display: 'flex', justifyContent: 'center' }}>
          <Pie options={options} data={data} />
        </div>
        <div className='d-flex justify-content-center mt-4' style={{ gap: '32px' }}>
          <div style={{ fontWeight: '500', color: '#165BAA', fontSize: '16px', display: 'flex', gap: '12px' }}>
            <div style={{ width: "48px", height: "20px", backgroundColor: "#ACD2FD", border: "2px solid #165BAA", borderRadius: '16px' }}></div>
            Male
          </div>
          <div style={{ fontWeight: '500', color: '#8C4CA1', fontSize: '16px', display: 'flex', gap: '12px' }}>
            <div style={{ width: "48px", height: "20px", backgroundColor: "#F0C2FF", border: "2px solid #8C4CA1", borderRadius: '16px' }}></div>
            Female
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default PieChart;
