import axios from 'axios';
import CryptoJS from "crypto-js";
import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useForm} from "react-hook-form";
import {FormattedMessage} from 'react-intl';
import {toast} from 'react-toastify';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import '../app-assets/css/core/menu/menu-types/horizontal-menu.css';
import '../app-assets/css/pages/snapchat-auth.css';
import '../app-assets/css/plugins/extensions/toastr.css';
import '../app-assets/css/plugins/forms/form-validation.css';
import SnapLogoWhite from '../app-assets/images/icons/PRIMARY - GHOST.svg';
import SnapLogoTop from '../app-assets/images/pages/PRIMARY-GHOST.svg';
import '../app-assets/vendors/css/extensions/toastr.css';
import CookieCard from '../component/BottomCard';
import {ROUTES} from '../constants/routes';
import './style.css';

const Login = (props) => {

    const {
        register,
        handleSubmit,
        formState: { errors },

    } = useForm();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const [enabledDisablebtn, setEnabledisabledbtn] = useState(false);
    const [captchaToken, setCaptchaToken] = useState("token");
    const captchaRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }
        try {
            const token = await executeRecaptcha('yourAction');
            if (token) {
                return token
            } else {
                // Handle the case when token is null
                console.error("ReCaptcha token is null");
            }
        } catch (error) {
            // Handle other potential errors
            console.error("Error verifying ReCaptcha:", error);
        }
    }, [executeRecaptcha]);


    const onSubmit = (data) => {
        setEnabledisabledbtn(true)
        setLoading(true);
        axios({
            method: 'POST',
            url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + '/login',
            data: {
                gRecaptchaToken: captchaToken,
                ...data
            },
            withCredentials: true,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Accept": 'application/json',
            }
        })
            .then((response) => {
                if (response.data.code === "100") {
                    document.getElementById("loginForm").reset();
                    toast.success(<FormattedMessage id="Please check your email for a Login link" />);
                    document.getElementById("loginForm").reset()
                    setEnabledisabledbtn(false)
                }
            }).catch((e) => {
                document.getElementById("loginForm").reset();
                toast.error(<FormattedMessage id="User not found. Request Access for a new account" />);
                setEnabledisabledbtn(false)
            }).finally(() => {
                setLoading(false)
                captchaRef?.current?.reset();
              })
    }

    useEffect(() => {
        handleReCaptchaVerify().then((token) => {
            axios({
              method: 'POST',
              url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + '/getCaptchaScore',
              data: {
                gRecaptchaToken: token
              }
            }).then((res) => {
              res.data.recaptcha.score > 0.7 ? setCaptchaToken(token) : setCaptchaToken("")
            }).catch((e) => {
                setCaptchaToken("")
            })
          }).finally(() => {
            setLoading(false)
          })
    },[handleReCaptchaVerify])

    useEffect(() => {
        if (captchaToken && captchaToken.length > 0) {
          setEnabledisabledbtn(true)
        }else{
          setEnabledisabledbtn(false)
        }
      }, [captchaToken])

    useEffect(() => {
        const generatedFlag = localStorage.getItem("userActiveFlag");
        if (generatedFlag !== null) {
            try {
                var bytes = CryptoJS.AES.decrypt(
                    generatedFlag,
                    process.env.REACT_APP_CRYPTO_JS_DECRYPT_KEY
                );
                var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                if (decryptedData === "true") {
                    props.history.push("/home");
                } else {
                    props.history.push("/login");
                }
            } catch (err) {
                console.error("Error parsing JSON:", err);
            }
        }

        document.getElementById("lang_en").style.color = "white";
        document.getElementById("lang_es").style.color = "white";
    }, [props.history]);

    const handleNavRouter = () => {
        props.history.push(ROUTES.HOME)
    }
    return (
        <div className="horizontal-layout horizontal-menu blank-page navbar-floating footer-fixed" data-open="hover" data-menu="horizontal-menu" data-col="blank-page">
            <nav className="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center bg-black" data-nav="brand-center">
                <div className="navbar-header d-xl-block d-none">

                </div>
                <div className="navbar-container d-flex content">
                    <div className="bookmark-wrapper d-flex align-items-center">
                        <ul className="nav navbar-nav d-xl-none" onClick={handleNavRouter}>
                            <li className="nav-item">
                                <a className="nav-link"> <img className="snap-main-icon-homepage-wrapper" src={SnapLogoTop} style={{ width: "29px", marginRight: "10px" }} /><span className="" style={{ fontSize: "18px" }}> <FormattedMessage id="Mobile Partner Gateway" /></span></a>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav" onClick={handleNavRouter}>
                            <li className="nav-item d-none d-lg-block">
                                <a className="nav-link"> <img alt={SnapLogoTop} src={SnapLogoTop} style={{ width: "29px", marginRight: "10px" }} /> <span style={{ fontSize: "18px" }}><FormattedMessage id="Mobile Partner Gateway" /></span> </a>

                            </li>
                        </ul>
                    </div>
                    <div className="nav navbar-nav align-items-center ml-auto">
                        <div className="nav navbar-nav d-xl-none">
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle
                                    tag="span"
                                    data-toggle="dropdown"
                                    aria-expanded={dropdownOpen}
                                    className="text-white"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                </DropdownToggle>
                                <DropdownMenu className="toppadding" style={{ top: '52px;' }}>
                                    <DropdownItem tag="a" href="/register" ><FormattedMessage id="Request Access" /></DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <ul className="nav navbar-nav d-none d-lg-block" >
                            <a href={ROUTES.REGISTER} className="btn btn-snapchat border-25 waves-effect waves-float fs-18 waves-light"><FormattedMessage id="Request Access" /></a>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="app-content content ">
                <div className="content-overlay"></div>
                <div className="content-wrapper">
                    <div className="content-header row">
                    </div>
                    <div className="content-body back-img">
                        <div className="auth-wrapper auth-v1 px-2">
                            <div className="auth-inner py-2">
                                <div className="card mb-0" style={{ borderRadius: "15px" }} >
                                    <div className="card-body" style={{ padding: "0px" }}>
                                        <div className="row mx-0">
                                            <div className="col-lg-6" style={{ backgroundColor: 'yellow', borderTopLeftRadius: "15px", borderBottomLeftRadius: "15px" }}>
                                                <div className="col-lg-12 text-center" style={{ marginTop: "140px" }}>
                                                    <img alt={SnapLogoWhite} src={SnapLogoWhite} style={{ width: "35px" }} />
                                                </div>
                                                <div className="col-lg-12 text-center mt-1 px-2">
                                                    <p className="text-darkk"><FormattedMessage id="Log In with your Mobile Partner Gateway account to continue" />.</p>
                                                </div>

                                            </div>
                                            <div className="col-lg-6">
                                                <div style={{ width: "100%", textAlign: "right", padding: "5px 0px 0px 0px" }}><a href="/">X</a></div>
                                                <h1 className="card-title mb-1 pb-1 pt-2 mt-5 font-weight-bolder " style={{ fontSize: "22px", fontWeight: "bold", color: "#000" }} ><FormattedMessage id="Log In" /></h1>

                                                <form className="auth-login-form mt-2 mb-5" id="loginForm" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-group">
                                                        <label className="form-label" style={{ color: "#000" }}><FormattedMessage id="Email Address" /></label>
                                                        {/* onChange={(e) => handleEmail(e)} */}
                                                        <input type="email" className="form-control input-color" id="login-email" name="email" placeholder="john@example.com" aria-describedby="login-email" tabIndex="1" autofocus required  {...register('email', { required: true })} />
                                                        {errors.email && <label style={{ color: "red", fontStyle: "normal", }}>Email is required.</label>}
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="px-2 fs-10 text-center  font-weight-bold" style={{ lineHeight: "1.1rem" }}>
                                                            <p style={{ color: "#000" }}><FormattedMessage id="Reminder: your use of the Snap Mobile Partner Gateway is subject to Snap's" /> <a href='https://www.snap.com/en-US/privacy/privacy-policy' target="_blank" rel="noreferrer"><FormattedMessage id="Privacy Policy" /></a> & <a href='https://www.snap.com/en-US/terms/mobile-partner-gateway-terms' target='_blank' rel="noreferrer"><FormattedMessage id="Mobile Partner Gateway Terms" /></a></p>
                                                        </div>
                                                    </div>
                                                    <div className="form-group text-center pb-2 d-flex flex-column align-items-center">
                                                        {captchaToken && captchaToken.length > 0 ? null
                                                            : <ReCAPTCHA
                                                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_KEY}
                                                            ref={captchaRef}
                                                            onChange={(value) => {
                                                                setCaptchaToken(value)
                                                            }}
                                                            onExpired={() => {
                                                                setCaptchaToken("")
                                                            }}
                                                            onErrored={() => {
                                                                setCaptchaToken("")
                                                            }}
                                                            />
                                                        }
                                                        <button type="submit" disabled={!enabledDisablebtn} className={"btn btn-snapchat border-25 fs-12 w-30 mt-1" + (!enabledDisablebtn ? " disabled-btn-main-wrapper" : "")} tabIndex="4" id="login-button" ><FormattedMessage id="Log In" /></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <footer className="footer footer-fixed footer-transperent">
                <p className="clearfix mb-0"><span className="float-md-left d-block d-md-inline-block mt-25"><a className="mr-1 fs-12 text-white" href="https://www.snap.com/en-US/terms/mobile-partner-gateway-terms" target="_blank" rel="noreferrer"><FormattedMessage id="Terms & Conditions" /></a><span className=" d-sm-inline-block"><a className="mr-50 fs-12 text-white" href="https://www.snap.com/en-US/privacy/privacy-policy" target="_blank" rel="noreferrer"><FormattedMessage id="Privacy Policy" /></a></span></span></p>
            </footer>
            <CookieCard />
        </div>
    )
}
export default Login;