import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import LoaderImage from '../../app-assets/images/icons/loaderIcons.gif';
import SideNav from '../../component/SideNavigator';
import Header from '../../component/TopHeader';
import verifyUser from '../Verifier';
import './supportPage.css';

import { FormattedMessage } from 'react-intl';
import { Spinner } from 'reactstrap';



const SupportPage = (props) => {
    const [headerEmail, setHeaderEmail] = useState("");
    const [firstTabFlag, setFirstTabFlag] = useState(true);
    const [secondTabFlag, setSecondTabFlag] = useState(false);
    const [otherInputFlag, setOtherInputFlag] = useState(false);
    const [subject, setSubject] = useState('')
    const [contactUsBody, setContactUsBody] = useState('');
    const [subjectFlag, setSubjectFlag] = useState(false);
    const [contactUsBodyFlag, setContactUsBodyFlag] = useState(false);
    const [subjectInputFlag, setSubjectInputFlag] = useState(false);
    const [loaderFlag, setLoaderFlag] = useState(false);
    const [FAQs, setFAQs] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        verifyUser().then((data) => {
            if (data.data.code === '100' && data.data.email) {
                setHeaderEmail(data.data.email)
            } else {
                props.history.push('/login')
            }
        }).catch(() => {
            props.history.push('/login')
        })
    }, [])

    useEffect(() => {
        let detailsOpened = document.querySelectorAll("details[open]");
        for (const item of detailsOpened) {
            // Close the details tag
            if (this != item) {
                item.removeAttribute("open");
            }
        }
    }, [FAQs]);


    const getFAQs = () => {
        setLoading(true)
        axios({
            method: 'GET',
            url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + `/getCmsData?pageName=support&sectionName=FAQs`,
            withCredentials: true,
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then((response) => {
            setFAQs(response.data.data.section_content.trim());
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
            let detailsOpened = document.querySelectorAll("details[open]");
            for (const item of detailsOpened) {
                // Close the details tag
                if (this != item) {
                    item.removeAttribute("open");
                }
            }
        });
    }
    const handleFirstTab = () => {
        setFirstTabFlag(true)
        setSecondTabFlag(false)
    }

    const handleSecondTab = () => {
        setFirstTabFlag(false)
        setSecondTabFlag(true)
        getFAQs();
    }

    const handleSubject = (e) => {
        setSubject(e.target.value)
        if (e.target.value === '') {
            setSubjectFlag(true)
        } else {
            setSubjectFlag(false)
        }
        if (e.target.value === 'Other') {
            setOtherInputFlag(true)

        } else {
            setSubject(e.target.value)
            setOtherInputFlag(false)
        }
    }

    const handleOtherSpecify = (e) => {
        setSubject(e.target.value)
        if (e.target.value === '') {
            setSubjectInputFlag(true)
        } else {
            setSubjectInputFlag(false)
        }

    }
    const handleDescription = (e) => {
        setContactUsBody(e.target.value)
        if (e.target.value === '') {
            setContactUsBodyFlag(true)
        } else {
            setContactUsBodyFlag(false)
        }
    }

    const handleContactUs = (e) => {
        e.preventDefault()
        if (subject === 'Other') {
            setSubjectFlag(false)
        }
        if (subject !== '' && subject !== 'Other' && contactUsBody !== '') {
            setLoaderFlag(true)
            axios({
                method: 'POST',
                url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + '/contactUs',
                data: {
                    subject: subject,
                    contactUsBody: contactUsBody,
                },
                withCredentials: true,
            }).then((response) => {
                if (response.status === 200) {
                    setLoaderFlag(false)
                    document.getElementById('contactUsForm').reset();
                    toast.success("Success, We'll get back to you soon...")

                }
            }).catch((err) => {
                setLoaderFlag(false)
                toast.error("Something went wrong: " + err.response.data.msg)
            })
        } else {
            setLoaderFlag(false)
            if (subject === '') {
                setSubjectFlag(true)
            }
            if (contactUsBody === '') {
                setContactUsBodyFlag(true)
            }
            if (subject === 'Other') {
                setSubjectInputFlag(true)
            }

        }


    }
    return (
        <div className="vertical-layout vertical-menu-modern  navbar-floating footer-static bg-white " data-open="click" data-menu="vertical-menu-modern" data-col="">

            <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow customCss">
                <Header history={props.history} email={headerEmail} />
            </nav>
            <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow" style={{ borderRight: "1px solid #dad8d8" }} data-scroll-to-active="true">
                <SideNav history={props.history} />
            </div>

            <div className="app-content content page-main-div-wrapper d-none d-lg-block">
                <div className="content-overlay"></div>
                <div className="header-navbar-shadow"></div>
                <div className="content-header row" style={{ borderBottom: "1px solid #8888881A" }}>
                    <div className="px-1 bg-white">
                        {loaderFlag && <div className="loader-component">
                            <img src={LoaderImage} className="image-loader-offers" />
                        </div>}
                        <ul className="nav nav-pills " style={{ backgroundColor: "yellow", borderRadius: "25px", height: "auto", alignItems: "center", paddingLeft: "5px", paddingRight: "5px", paddingTop: "3px", paddingBottom: "3px" }}>
                            <li className="nav-item" onClick={() => handleFirstTab()}>
                                <a className={"nav-link text-darkk " + (firstTabFlag ? " active no-padding" : "")} id="faq-tab" data-toggle="pill" aria-expanded="false"><FormattedMessage id="Contact us" /></a>
                            </li>
                            <li className="nav-item" onClick={() => handleSecondTab()}>
                                <a className={"nav-link text-darkk " + (secondTabFlag ? " active no-padding" : "")} id="contactus-tab" data-toggle="pill" aria-expanded="false"><FormattedMessage id="FAQ" /></a>
                            </li>

                        </ul>
                    </div>

                </div>
                <div className="content-wrapper">
                    <div className="content-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content">
                                    {loading ?
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '67vh'
                                            }}>
                                            <Spinner style={{ height: '80px', width: '80px', fontSize: "32px" }} />
                                        </div>
                                        : <div className={"tab-pane mt-2 " + (secondTabFlag ? " active" : "")} id="faq-tab" role="tabpanel" aria-labelledby="faq-tab" aria-expanded="false">
                                            <div className="card mt-2 collapse-icon accordion-icon-rotate">
                                                <div className="card-header card-header-bg">
                                                    <div className="col-lg-12">
                                                        <h4 className="card-title text-darkk"><FormattedMessage id="Frequently Asked Questions" /></h4>
                                                    </div>
                                                </div>
                                                <div className="card-content">
                                                    <div className="card-body">
                                                        <div className="accordion-default collapse-bordered">
                                                            <div className="card collapse-header border-0 " dangerouslySetInnerHTML={{ __html: FAQs }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }



                                    <div className={"tab-pane mt-2 " + (firstTabFlag ? " active" : "")} id="contactus-tab" role="tabpanel" aria-labelledby="contactus-tab" aria-expanded="false">
                                        <div className="card mt-2 collapse-icon accordion-icon-rotate">
                                            <div className="card-header card-header-bg">
                                                <h4 className="card-title text-darkk"><FormattedMessage id="Contact us" /></h4>
                                            </div>
                                            <div className="card-content">
                                                <div className="card-body">
                                                    <form id='contactUsForm'>
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label htmlFor="marketingFormDesc" className="mb-1 mt-1"><FormattedMessage id="Subject" /></label>
                                                                <select className="form-control border-25" onChange={(e) => handleSubject(e)} >
                                                                    <FormattedMessage id="Please select">
                                                                        {(message) => <option value="">{message}</option>}
                                                                    </FormattedMessage>
                                                                    <FormattedMessage id="I need technical support">
                                                                        {(message) => <option value="I need technical support">{message}</option>}
                                                                    </FormattedMessage>
                                                                    <FormattedMessage id="I would like to discuss partnership opportunities with Snap">
                                                                        {(message) => <option value="I would like to discuss partnership opportunities with Snap">{message}</option>}
                                                                    </FormattedMessage>
                                                                    <FormattedMessage id="I have feedback or questions about this web portal">
                                                                        {(message) => <option value="I have feedback or questions about this web portal">{message}</option>}
                                                                    </FormattedMessage>
                                                                    <FormattedMessage id="Other">
                                                                        {(message) => <option value="Other">{message}</option>}
                                                                    </FormattedMessage>
                                                                </select>
                                                                {subjectFlag && <label style={{ color: "red", fontStyle: "normal", }}><FormattedMessage id="This field is required" />.</label>}
                                                                <div >
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {otherInputFlag && <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="marketingFormDesc" className="mb-1 mt-1"><FormattedMessage id="Please Specify" /></label>
                                                                    <input className="form-control input-color" id="marketingFormDesc" name="marketingFormDesc" rows="4" spellCheck="false" onChange={(e) => handleOtherSpecify(e)}></input>
                                                                    {subjectInputFlag && <label style={{ color: "red", fontStyle: "normal", }}><FormattedMessage id="This field is required" />.</label>}
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="marketingFormDesc" className="mb-1 mt-1"><FormattedMessage id="Description" /></label>
                                                                    <textarea className="form-control input-color" id="marketingFormDesc" name="marketingFormDesc" rows="4" spellCheck="false" onChange={(e) => handleDescription(e)}></textarea>
                                                                    {contactUsBodyFlag && <label style={{ color: "red", fontStyle: "normal", }}><FormattedMessage id="This field is required" />.</label>}

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 mt-1 mb-1">
                                                                <button type='submit' className="btn btn-snapchat mr-1 waves-effect border-25 waves-float waves-light" onClick={(e) => handleContactUs(e)}><FormattedMessage id="Submit" /></button>
                                                            </div>
                                                        </div>

                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="sidenav-overlay"></div>
                <div class="drag-target"></div>

            </div>
            <div className="fullscreenDiv d-xl-none d-lg-none d-md-block d-sm-block d-xs-block" style={{ textAlign: "center", }}>
                <div className="center text-dark font-weight-bolder">
                    <h1><FormattedMessage id="Works best on Desktop" />. </h1>
                </div>
            </div>
        </div >
    )
}
export default SupportPage;