import { React, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import RoutePage from './Routes';



import Switch from 'react-bootstrap/esm/Switch';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import messagesEn from './locales/en.json';
import messagesEs from './locales/es.json';




function App() {
  const menuMessages = {
    en: { ...messagesEn },
    es: { ...messagesEs }
  }

  const [locale, setLocale] = useState(sessionStorage.getItem("lang") || "en");
  const [messages, setMessages] = useState(menuMessages[locale]);
  const [pathname, setpathName] = useState("/")

  useEffect(() => {
    setMessages(menuMessages[locale])
  }, [locale])

  useEffect(() => {
    setpathName(window.location.pathname);
    if (window.location.pathname === "/" || window.location.pathname === "/register" || window.location.pathname === "/login") {
      document.getElementById("lang_en").style.color = "white";
      document.getElementById("lang_es").style.color = "white";
    } else {
      document.getElementById("lang_en").style.color = "black";
      document.getElementById("lang_es").style.color = "black";
    }
  }, [pathname])


  const handleCheckBox = (e) => {
    if (locale === "en") {
      setLocale("es");
      sessionStorage.setItem("lang", "es");
    } else {
      setLocale("en");
      sessionStorage.setItem("lang", "en");
    }
  }

  return (
    <IntlProvider messages={messages} locale="en" defaultLocale="en">
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: "head",
          nonce: undefined,
        }}>

        <div className="custom-control custom-control-success custom-switch" style={{ zIndex: "11111", display: "flex", position: "fixed", top: "20px", right: "25%" }}>
          <label id="lang_en" className='text-white pr-1' style={{ fontSize: '13px', fontWeight: 500 }} > English </label>
          <Switch checked={locale === 'es' ? true : false} id="laguageChange" onChange={(e) => handleCheckBox(e)} />
          <label id="lang_es" className='text-white' style={{ marginLeft: "-38px", fontSize: '13px', fontWeight: 500 }} > Español </label>
        </div>

        <div>
          <ToastContainer />
          <RoutePage />
        </div>
      </GoogleReCaptchaProvider>
    </IntlProvider>
  );
}

export default App;
