import { useState } from "react";
import { Search } from "react-feather";
import { Button, Input } from "reactstrap";

// Search Bar Component
const SearchBar = ({
  placeholder,
  handleSearch,
  handleReset,
  resetVisible
}) => {
  const [searchInput, setSearchInput] = useState('');

  return (
    <div className='d-flex justify-content-start align-items-center mb-2' style={{ gap: "12px", position: 'relative', flex: '1' }}>
      <Input
        placeholder={placeholder}
        className="col-2"
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
        style={{ textIndent: "18px" }}
      />
      <Search
        style={{
          position: "absolute", left: "8px",
          width: "16px",
          color: "#afafaf"
        }}
      />
      <button
        className="rounded"
        outline
        style={{
          color: "black",
          background: "none",
          padding: "7px 16px 7px 16px"
        }}
        onClick={() => handleSearch(searchInput)}
      >
        Search
      </button>
      {resetVisible ?
        <Button
          outline
          color="secondary"
          onClick={() => { handleReset(); setSearchInput('') }}
        >
          Reset
        </Button> : null
      }
    </div >
  );
};

export default SearchBar;
