import React from 'react';

const PageNotFound = () => {
    return (
        <>
            <div style={{ fontSize: "30px", textAlign: "center", paddingTop: "10%" }}>
                Page Not Found
            </div>
            <p class="mb-4" style={{textAlign:"center"}}>Oops! 😖 The requested URL was not found on this server.</p>
        </>
    )
}

export default PageNotFound;