import axios from 'axios';
import CryptoJS from "crypto-js";
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import SideNav from '../../component/SideNavigator';
import Header from '../../component/TopHeader';
import { ROUTES } from '../../constants/routes';
import { trimText } from '../../utils/trimText';
import './dashboard.css';

const Dashboard = (props) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("User")
  const [loading, setLoading] = useState(true);
  const [topLeftContent, setTopLeftContent] = useState({
    sectionTitle: '',
    sectionContent: '',
    buttonTitle: '',
    buttonUrl: ''
  });
  const [topRightContent, setTopRightContent] = useState({
    sectionTitle: '',
    sectionContent: '',
    buttonTitle: '',
    buttonUrl: ''
  });
  const [bottomLeftContent, setBottomLeftContent] = useState({
    sectionTitle: '',
    sectionContent: '',
    buttonTitle: '',
    buttonUrl: ''
  });
  const [bottomRightContent, setBottomRightContent] = useState({
    sectionTitle: '',
    sectionContent: '',
    buttonTitle: '',
    buttonUrl: ''
  });


  useEffect(() => {
    const value = queryString.parse(props.location.search);
    const token = value.Token;

    if (token) {
      const payload = {
        token: token
      }
      axios({
        method: 'POST',
        url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + '/verifyUser',
        data: payload,
        withCredentials: true,
      }).then((response) => {
        if (response.status === 200) {
          setFirstName(response.data.fname);
          setEmail(response.data.email)
          if (typeof (Storage) !== "undefined") {
            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify("true"), process.env.REACT_APP_CRYPTO_JS_DECRYPT_KEY).toString();
            localStorage.setItem("userActiveFlag", ciphertext);
          }
          Promise.all([
            getDashboardContent("Top Left"),
            getDashboardContent("Top Right"),
            getDashboardContent("Bottom Left"),
            getDashboardContent("Bottom Right"),
          ]).then(([topLeft, topRight, bottomLeft, bottomRight]) => {
            setTopLeftContent(topLeft);
            setTopRightContent(topRight);
            setBottomLeftContent(bottomLeft);
            setBottomRightContent(bottomRight);
          }).finally(() => { setLoading(false) });

        }
      }).catch((err) => {
        toast.error("Link expired. Please login again.");
        props.history.push(ROUTES.LOGIN);
      })
    }
    else {

      axios({
        method: 'POST',
        url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + '/verifyUser',
        data: {},
        withCredentials: true,
      }).then((response) => {
        if (response.status === 200) {
          setFirstName(response.data.fname)
          setEmail(response.data.email)
          let ciphertext = CryptoJS.AES.encrypt(JSON.stringify("true"), process.env.REACT_APP_CRYPTO_JS_DECRYPT_KEY).toString();
          localStorage.setItem("userActiveFlag", ciphertext);
          Promise.all([
            getDashboardContent("Top Left"),
            getDashboardContent("Top Right"),
            getDashboardContent("Bottom Left"),
            getDashboardContent("Bottom Right"),
          ]).then(([topLeft, topRight, bottomLeft, bottomRight]) => {
            setTopLeftContent(topLeft);
            setTopRightContent(topRight);
            setBottomLeftContent(bottomLeft);
            setBottomRightContent(bottomRight);
          }).finally(() => { setLoading(false) });
        }
        else {
          let ciphertext = CryptoJS.AES.encrypt(JSON.stringify("false"), process.env.REACT_APP_CRYPTO_JS_DECRYPT_KEY).toString();
          localStorage.setItem("userActiveFlag", ciphertext);
          props.history.push(ROUTES.LOGIN);
        }
      }).catch((err) => {
        let ciphertext = CryptoJS.AES.encrypt(JSON.stringify("false"), process.env.REACT_APP_CRYPTO_JS_DECRYPT_KEY).toString();
        localStorage.setItem("userActiveFlag", ciphertext);
        props.history.push(ROUTES.LOGIN);
      })
    }

  }, [props.history, props.location.search])

  const getDashboardContent = async (sectionName) => {
    setLoading(true);
    try {
      const response = await axios({
        method: "GET",
        url:
          process.env["REACT_APP_BACKEND_URL_" + process.env.REACT_APP_ENV] +
          `/getCmsData?pageName=home&sectionName=${sectionName}`,
        withCredentials: true,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      return {
        sectionTitle: response.data.data.section_title,
        sectionContent: trimText(response.data.data.section_content, 400),
        buttonTitle: response.data.data.section_button || '',
        buttonUrl: response.data.data.button_url || '',
      };
    } catch (err) {
      console.error(err);
      return {
        sectionTitle: "",
        sectionContent: "",
        buttonTitle: "",
        buttonUrl: "",
      };
    }
  };

  return (
    <div className="vertical-layout vertical-menu-modern  navbar-floating footer-static bg-white " data-open="click" data-menu="vertical-menu-modern" data-col="">

      <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow customCss" >
        <Header email={email} history={props.history} />
      </nav>
      <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow d-none d-lg-block" style={{ borderRight: "1px solid #dad8d8" }} data-scroll-to-active="true">
        <SideNav history={props.history} />
      </div>

      <div className="app-content content page-main-div-wrapper d-none d-lg-block">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">

          <div className="content-body">
            <div className="col-lg-12 p-2">

              {loading ?
                <div
                  style={{
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '67vh'
                  }}>
                  <Spinner style={{ height: '70px', width: '70px', fontSize: "28px" }} />
                </div>
                : <>
                  <div className='text-darkk' style={{ marginBottom: "20px" }}>
                    <h1 className="text-darkk dashboardFont" style={{ fontWeight: '600' }}><FormattedMessage id="Hello" /> {firstName},</h1>
                    <h1 className="text-darkk dashboardFont" style={{ fontSize: '24px', fontWeight: '600' }}><FormattedMessage id="Welcome to the Snap Mobile Partner Gateway" /></h1>
                  </div>
                  <div className='col-12 p-0' style={{ display: "grid", gridTemplate: "auto auto / auto auto", gap: "24px" }}>
                    {/*Top Part */}
                    {/*Top Left*/}
                    <div className='big-block'>
                      <h1 className='text-darkk dashboardFont header1' style={{ flex: '0 0 auto' }}>{topLeftContent.sectionTitle}</h1>
                      <span className='text400 text-darkk dashboardFont text-wrapper text-clamp' style={{ flex: '1 1 auto' }} dangerouslySetInnerHTML={{ __html: topLeftContent.sectionContent }}></span>
                      {!!topLeftContent.buttonTitle.length && <a href={topLeftContent.buttonUrl} className='action-button'>{topLeftContent.buttonTitle}</a>}
                    </div>
                    {/*Top Right*/}
                    <div className='small-block'>
                      <h1 className='text-darkk dashboardFont header1' style={{ flex: '0 0 auto' }}>{topRightContent.sectionTitle}</h1>
                      <span className='text400 text-darkk dashboardFont text-wrapper text-clamp' style={{ flex: '1 1 auto' }} dangerouslySetInnerHTML={{ __html: topRightContent.sectionContent }}></span>
                      {!!topRightContent.buttonTitle.length && <a href={topRightContent.buttonUrl} className='action-button'>{topRightContent.buttonTitle}</a>}
                    </div>

                    {/*Bottom Part */}
                    {/*Bottom Left*/}
                    <div className='small-block'>
                      <h1 className='text-darkk dashboardFont header1' style={{ flex: '0 0 auto' }}>{bottomLeftContent.sectionTitle}</h1>
                      <span className='text400 text-darkk dashboardFont text-wrapper text-clamp' style={{ flex: '1 1 auto' }} dangerouslySetInnerHTML={{ __html: bottomLeftContent.sectionContent }}></span>
                      {!!bottomLeftContent.buttonTitle.length && <a href={bottomLeftContent.buttonUrl} className='action-button'>{bottomLeftContent.buttonTitle}</a>}
                    </div>
                    {/*Bottom Right*/}
                    <div className='big-block'>
                      <h1 className='text-darkk dashboardFont header1' style={{ flex: '0 0 auto' }}>{bottomRightContent.sectionTitle}</h1>
                      <span className='text400 text-darkk dashboardFont text-wrapper text-clamp' style={{ flex: '1 1 auto' }} dangerouslySetInnerHTML={{ __html: bottomRightContent.sectionContent }}></span>
                      {!!bottomRightContent.buttonTitle.length && <a href={bottomRightContent.buttonUrl} className='action-button'>{bottomRightContent.buttonTitle}</a>}
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="sidenav-overlay"></div>
      <div className="fullscreenDiv d-xl-none d-lg-none d-md-block d-sm-block d-xs-block">
        <div className="center text-dark font-weight-bolder">
          <h1>Works best on Desktop. </h1>
        </div>
      </div>



    </div >
  )
}

export default Dashboard;