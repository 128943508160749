import 'flatpickr/dist/themes/light.css';
import React, { useEffect, useState } from 'react';
import { Download, MoreVertical, Trash2 } from "react-feather";
import SideNav from '../../component/SideNavigator';
import Header from '../../component/TopHeader';
import verifyUser from '../Verifier';

import 'sweetalert2/dist/sweetalert2.css';

import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner, Table } from 'reactstrap';
import SearchBar from '../../component/SearchBar';
import TablePagination from '../../component/TablePagination';
import { ROUTES } from '../../constants/routes';
import UploadModalForm from './UploadModalForm';




const FileSharing = (props) => {
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [filters, setFilters] = useState('');
  const [loading, setLoading] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [pageCountValue, setPageCountValue] = useState(1);
  const [carrierId, setCarrierId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [resetVisible, setResetVisible] = useState('');
  const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search));
  const history = useHistory();
  const location = useLocation();
  const { search } = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState({});

  const toggleDropdown = (companyId) => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [companyId]: !prevState[companyId]
    }));
  }

  const getAllFiles = (page = 1, carrierId, searchFilter = "") => {
    setLoading(true)
    axios({
      method: 'GET',
      url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + `/getOfferDocuments?page=${page}&limit=10&searchFilter=${searchFilter ? searchFilter : ""}`,
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Accept": 'application/json',
      }
    }).then((response) => {
      setFileList(response.data.data.items)
      setPageCountValue(response.data.data.totalItems || 1)
    })
      .catch((err) => {
        toast.error("not able to get all files")
      })
      .finally(() => setLoading(false))
  }

  const handleDeleteFile = (fileId) => {
    setLoading(true)
    axios({
      method: 'DELETE',
      url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + '/deleteOfferDocument',
      data: {
        file_key: fileId,
      },
      withCredentials: true,
    }).then(() => {
      getAllFiles(1, carrierId)
      toast.success("File Deleted Successfully")
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleDownload = (file_key) => {
    axios({
      method: 'GET',
      url: process.env['REACT_APP_BACKEND_URL_' + process.env.REACT_APP_ENV] + '/assets/download?key=' + file_key,
      withCredentials: true,
    }).then((res) => {
      const downloadFile = () => {
        const link = document.createElement("a");
        link.href = res.data.url;
        link.download = res.data.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      downloadFile();
    })
      .catch((err) => {
        toast.error(err.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }


  useEffect(() => {
    verifyUser().then((data) => {
      if (data.data.code === '100' && data.data.email) {
        setEmail(data.data.email)
        setFirstName(data.data.fname)
        setLastName(data.data.lname)
      } else {
        props.history.push(ROUTES.LOGIN)
      }
    }).catch(() => {
      props.history.push(ROUTES.LOGIN)
    })
    getAllFiles(1, carrierId)
  }, [])

  useEffect(() => {
    const page = search.match(/\?page=(\d+)/);
    if (page) {
      setCurrentPage(page[1])
      getAllFiles(page[1], carrierId)
    }
  }, [search])


  const handleSearch = (searchInput) => {
    getAllFiles(1, carrierId, searchInput);
    setSearchInput("");
    setResetVisible(true);
    if (searchParams.has("page")) {
      searchParams.set("page", 1);
    } else {
      searchParams.append("page", 1);
    }
    setSearchParams(searchParams);
    setCurrentPage(1);
    history.replace({ pathname: location.pathname, search: searchParams.toString() });
  };

  const handleReset = () => {
    getAllFiles(1, carrierId);
    setResetVisible(false);
  };


  return (
    <div className="vertical-layout vertical-menu-modern  navbar-floating footer-static bg-white " data-open="click" data-menu="vertical-menu-modern" data-col="">

      <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow customCss">
        <Header email={email} history={props.history} />
      </nav>
      <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow" style={{ borderRight: "1px solid #dad8d8" }} data-scroll-to-active="true">
        <SideNav history={props.history} />
      </div>

      <div className="app-content content page-main-div-wrapper d-none d-lg-block">

        <div className="content-overlay"></div>
        <div className="header-navbar-shadow">
        </div>
        <div className="content-wrapper">
          <div className="content-body">
            <div className="row">
              <div className="col-12">
                <div className="tab-content">
                  <div className='d-flex align-items-center justify-content-between mb-2'>
                    <h1 style={{ fontSize: "34px", color: "#4B4B4B" }}><FormattedMessage id="File Sharing" /></h1>
                    <UploadModalForm
                      getAllFiles={getAllFiles}
                      setLoading={setLoading}
                      userEmail={email}
                    />
                  </div>
                  <SearchBar
                    placeholder="Search by file name"
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    resetVisible={resetVisible}
                  />
                  <Card>
                    <span className='p-1'>Showing &nbsp;{(currentPage * 10) > pageCountValue ? pageCountValue : (currentPage * 10)} of {pageCountValue}</span>
                    {loading ? <Spinner
                      style={{
                        width: "70px",
                        height: '70px',
                        alignSelf: "center",
                        borderWidth: '6px',
                        margin: "150px"
                      }} /> :
                      <Table>
                        <thead>
                          <tr className='p-2'>
                            <th className='p-1' style={{ background: "none" }}>TITLE</th>
                            <th className='p-1' style={{ background: "none" }}>FILE NAME</th>
                            <th className='p-1' style={{ background: "none" }}>UPLOADED BY</th>
                            <th className='p-1' style={{ background: "none" }}>UPLOADED ON</th>
                            <th className='p-1' style={{ width: "40px", background: "none" }}>TAGS</th>
                            <th className='p-1' style={{ background: "none" }}>ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fileList.length > 0 ? fileList?.map((item, index) => (
                            <tr key={index} style={{ background: ((index + 1) % 2) ? "#F2F2F2" : "none" }}>
                              <td className='py-1' style={{ fontSize: "14px", fontWeight: 400 }}>
                                {item.file_title}
                              </td>
                              <td className='py-1' style={{ fontSize: "14px", fontWeight: 400 }}>{item.file_name}</td>
                              <td className='py-1' style={{ fontSize: "14px", fontWeight: 400 }}>
                                <div className='d-flex flex-column justify-content-center '>
                                  <span className='font-weight-bold text-dark  '>{item.company_name}</span>
                                  <span>{item.uploaded_by}</span>
                                </div>
                              </td>
                              <td className='py-1' style={{ fontSize: "14px", fontWeight: 400 }}>
                                {new Date(item.updatedAt).toLocaleString("en-US", {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })}
                              </td>

                              <td style={{ width: "160px", display: "flex", flexWrap: "wrap", gap: "8px" }}>
                                {item.offer_tags?.map((tag, index) => {
                                  if (index < 2) {
                                    return <span
                                      key={index}
                                      className="rounded"
                                      style={{
                                        padding: "4px 8px 4px 8px",
                                        background: "#DBD8D8",
                                        fontSize: "14px",
                                        fontWeight: 400
                                      }}>
                                      {tag}
                                    </span>;
                                  } else if (index === 2) {
                                    const remaining = item.offer_tags.length - 2;
                                    return <span
                                      key={index}
                                      className="rounded"
                                      style={{
                                        padding: "4px 8px 4px 8px",
                                        background: "#DBD8D8",
                                        fontSize: "12px"
                                      }}
                                    >
                                      +{remaining}
                                    </span>;
                                  } else return null;
                                })}
                              </td>

                              <td>
                                <Dropdown
                                  isOpen={
                                    dropdownOpen[
                                    item.file_key
                                    ] || false
                                  }
                                  toggle={() =>
                                    toggleDropdown(
                                      item.file_key
                                    )
                                  }
                                  direction="down"
                                >
                                  <DropdownToggle color="none" style={{ padding: "0px", boxShadow: "none" }}>
                                    <MoreVertical
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    style={{
                                      padding: "0px",
                                      marginLeft: "-66px",
                                      width: "150px"
                                    }}
                                  >
                                    <DropdownItem
                                      className="w-100"
                                      style={{
                                        paddingLeft: "8px",
                                        paddingRight: "8px",
                                      }}
                                    >
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none",
                                          outline: "none",
                                          boxShadow: "none",
                                          display: "flex",
                                          gap: "8px",
                                          padding: '0px',
                                          color: "#42414b",
                                        }}
                                        onClick={() =>
                                          handleDownload(
                                            item.file_key
                                          )
                                        }
                                      >
                                        <Download
                                          style={{
                                            width: "20px",
                                            height:
                                              "20px",
                                          }}
                                        />
                                        <span className="font-weight-bold">
                                          Download
                                        </span>
                                      </button>
                                    </DropdownItem>
                                    <DropdownItem
                                      className="w-100"
                                      style={{
                                        paddingLeft:
                                          "8px",
                                        paddingRight:
                                          "8px",
                                      }}
                                    >
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none",
                                          outline: "none",
                                          boxShadow: "none",
                                          display: "flex",
                                          gap: "8px",
                                          padding: '0px',
                                          color: "#42414b",
                                        }}
                                        onClick={() =>
                                          handleDeleteFile(
                                            item.file_key
                                          )
                                        }
                                      >
                                        <Trash2
                                          style={{
                                            width: "20px",
                                            height:
                                              "20px",
                                          }}
                                        />
                                        <span className="font-weight-bold">
                                          Delete
                                        </span>
                                      </button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          )) :
                            <tr>
                              <td colSpan={6} className="font-weight-bold text-center py-5" style={{ fontSize: '18px', color: "#000" }}><FormattedMessage id="No Records Found" /></td>
                            </tr>
                          }
                        </tbody>
                      </Table>
                    }

                  </Card>
                </div>
                <div className='mt-2'>
                  <TablePagination pageSize={10} itemCount={pageCountValue} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="fullscreenDiv d-xl-none d-lg-none d-md-block d-sm-block d-xs-block" style={{ textAlign: "center", }}>
        <div className="center text-dark font-weight-bolder">
          <h1><FormattedMessage id="Works best on Desktop" /> </h1>
        </div>
      </div>
    </div >
  )
}
export default FileSharing;