import CryptoJS from "crypto-js";
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import '../../app-assets/css/core/menu/menu-types/horizontal-menu.css';
import '../../app-assets/css/plugins/extensions/toastr.css';
import SnapLogoTop from '../../app-assets/images/pages/PRIMARY-GHOST.svg';
import '../../app-assets/vendors/css/extensions/toastr.css';
import CookieCard from '../../component/BottomCard';
import './homePage.css';

import { FormattedMessage } from 'react-intl';
import { ROUTES } from "../../constants/routes";

const HomePage = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);
    useEffect(() => {
        try {
            const generatedFlag = localStorage.getItem("userActiveFlag");
            if (generatedFlag !== null) {
                var bytes = CryptoJS.AES.decrypt(
                    generatedFlag,
                    process.env.REACT_APP_CRYPTO_JS_DECRYPT_KEY
                );
                var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                if (decryptedData === "true") {
                    props.history.push(ROUTES.DASHBOARD);
                } else {
                    props.history.push(ROUTES.HOME);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, [props.history]);


    const handleNavRouter = () => {
        props.history.push(ROUTES.HOME)
    }
    return (
        <div className="horizontal-layout horizontal-menu footer-static  " data-open="hover" data-menu="horizontal-menu" data-col="">

            <nav className="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center bg-black" data-nav="brand-center">
                <div className="navbar-header d-xl-block d-none">

                </div>
                <div className="navbar-container d-flex content">
                    <div className="bookmark-wrapper d-flex align-items-center">
                        <ul className="nav navbar-nav d-lg-none d-md-block d-sm-block d-xs-block" onClick={handleNavRouter}>
                            <li className="nav-item">
                                {/* <a className="nav-link menu-toggle" ><i className="ficon" data-feather="menu"></i></a> */}
                                <a className="nav-link"> <img className="snap-main-icon-homepage-wrapper" src={SnapLogoTop} style={{ width: "29px", marginRight: "12px" }} /><span className="" style={{ fontSize: "20px" }}> <FormattedMessage id="Mobile Partner Gateway" /></span></a>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav" onClick={handleNavRouter}>
                            <li className="nav-item d-none d-lg-block d-md-none d-sm-none d-xs-none">
                                <a className="nav-link"> <img className="snap-main-icon-homepage-wrapper" src={SnapLogoTop} style={{ width: "29px", marginRight: "12px", marginTop: '-4px' }} /><span className="" style={{ fontSize: "20px", fontWeight: '500' }}> <FormattedMessage id="Mobile Partner Gateway" /></span></a>
                            </li>
                        </ul>
                    </div>
                    <div className="nav navbar-nav align-items-center ml-auto">
                        <div className="nav navbar-nav d-lg-none d-md-block d-sm-block d-xs-block">
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle
                                    tag="span"
                                    data-toggle="dropdown"
                                    aria-expanded={dropdownOpen}
                                    className="text-white"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                </DropdownToggle>
                                <DropdownMenu className="toppadding" style={{ top: '52px;' }}>
                                    <DropdownItem tag="a" href="/register" ><FormattedMessage id="Request Access" /></DropdownItem>
                                    <DropdownItem tag="a" href="/login" ><FormattedMessage id="Log In" /></DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <ul className="nav navbar-nav d-none d-lg-block" onClick={handleNavRouter}>
                            <a href="/register" className="btn btn-snapchat border-25 waves-effect waves-float waves-light mr-50" style={{ fontSize: "16px" }}><FormattedMessage id="Request Access" /></a>
                            <a href="/login" className="btn btn-snapchat border-25 waves-effect waves-float waves-light ml-50" style={{ fontSize: "16px" }}><FormattedMessage id="Log In" /></a>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="app-content content bg-black">
                <div className="content-overlay"></div>
                {/* <div className="header-navbar-shadow"></div> for testing  */}
                <div className="content-wrapper" >
                    <div className="back-img" style={{ height: "100vh" }}>
                        <h1 className="h1-center d-none d-lg-block" style={{ fontSize: "50px" }}><FormattedMessage id="Partner with Snap to grow your business" /></h1>
                        <h1 className="h1-center d-xl-none px-1" style={{ fontSize: "35px" }}><FormattedMessage id="Partner with Snap to grow your business" /></h1>
                    </div>
                </div>
            </div>

            <div className="sidenav-overlay"></div>
            <div className="drag-target"></div>


            <CookieCard />

        </div>
    )
}

export default HomePage;