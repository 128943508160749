import { Chart } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { darkenHsla } from '../../utils/darkenHsla';

const DoughnutChart = ({ options, data, className, style }) => {
  //Code for extending functionality to insert titles inside bubbles
  Chart.plugins.register({
    afterDatasetsDraw: function (chartInstance, easing) {
      // To only draw at the end of animation, check for easing === 1
      if (!(chartInstance.config.type === 'bubble')) {
        let ctx = chartInstance.chart.ctx;
        chartInstance.data.datasets.forEach(function (dataset, i) {
          let meta = chartInstance.getDatasetMeta(i);
          if (!meta.hidden) {
            meta.data.forEach(function (element, index) {
              // Draw the text in black, with the specified font
              ctx.fillStyle = darkenHsla(dataset.backgroundColor);
              let fontSize = 18;
              let fontStyle = "inherit";
              ctx.font = Chart.helpers.fontString(fontSize, fontStyle);
              // Just naively convert to string for now
              // <---- ADJUST TO DESIRED TEXT --->
              let dataString = "";
              if (dataset.label) {
                dataString = dataset.label.toString();
              } else {
                dataString = dataset.data[index].toString() + "%";
              }
              // Make sure alignment settings are correct
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              let position = element.tooltipPosition();
              ctx.fillText(dataString, position.x, position.y);
            });

          }
        });
      }
    }
  })

  return (
    <Card
      style={{
        borderRadius: '16px',
        boxShadow: '0px 4px 0px 0px #CCCCCC',
        border: '1px solid #CCCCCC',
        ...style
      }}
      className={`${className}`}
    >
      <CardTitle
        className="p-2 card-header-bg"
        style={{
          borderBottom: "1px solid #CCCCCC",
          color: 'black',
          fontSize: '18px',
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          margin: '0px'
        }}
      >
        Operating System Usage Ratio
      </CardTitle>
      <CardBody
        className='px-0'
        style={{
          padding: '0',
          display: 'flex',
          flexDirection: "column",
          gap: '24px',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ width: '125%', display: 'flex', justifyContent: 'center' }}>
          <Doughnut options={options} data={data} />
        </div>
        <div className='d-flex flex-row justify-content-center mt-2' style={{ gap: '32px' }}>
          <div style={{ fontWeight: '500', color: '#45965B', fontSize: '16px', display: 'flex', gap: '12px' }}>
            <div style={{ width: "48px", height: "20px", backgroundColor: "#BDFFCE", border: "2px solid #45965B", borderRadius: '16px' }}></div>
            Android
          </div>
          <div style={{ fontWeight: '500', color: '#A14C4C', fontSize: '16px', display: 'flex', gap: '12px' }}>
            <div style={{ width: "48px", height: "20px", backgroundColor: "#FFC2C2", border: "2px solid #A14C4C", borderRadius: '16px' }}></div>
            IOS
          </div>
        </div>
      </CardBody>
    </Card >
  )
}

export default DoughnutChart;
